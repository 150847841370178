import { useEffect } from "react";
import { useMetaTags } from "react-metatags-hook";

// OurTeam.js
export const OurTeam = () => {
  useEffect(() => {
    if (document.getElementsByClassName("mainContainer").length > 0)
      document.getElementsByClassName(
        "mainContainer"
      )[0].className = `mainContainer bg-green bg-opacity-60`;
  }, []);

  useMetaTags(
    {
      title: "Ekibimiz - Cronwork Dijital Ajans",
      description:
        "Firmalar için yazılım geliştirme desteği sunuyoruz. Blockchain, web app, crm ve bayi paneli çözümleri.",
    },
    []
  );

  return (
    <div>
      <h2 className="font-['Trenda_Bold'] tracking-[1px] text-[38px] sm:text-[48px] md:text-[62px] mb-[15px] leading-[1.2]">
        ekibimiz
      </h2>
      <ul className="w-full font-['Trenda_Semibold'] text-[22px] md:text-[22px] lg:text-[24px] leading-[1.6] tracking-[2px] flex flex-col">
        <li className="cursor-default hover:opacity-[1] opacity-[0.5]">
          <div className="flex flex-col sm:!flex-row gap-[5px] items-start sm:!items-center justify-between transition-all mb-[10px] sm:!mb-0">
            <span>Doğu Gürdal</span>
            <u className="bg-green bg-opacity-60">
              Kurucu & Blockchain Developer
            </u>
          </div>
        </li>

        <li className="cursor-default hover:opacity-[1] opacity-[0.5]">
          <div className="flex flex-col sm:!flex-row gap-[5px] items-start sm:!items-center justify-between transition-all mb-[10px] sm:!mb-0">
            Ali Dursun
            <u className="bg-green bg-opacity-60">Full Stack Developer</u>
          </div>
        </li>
        <li className="cursor-default hover:opacity-[1] opacity-[0.5]">
          <div className="flex flex-col sm:!flex-row gap-[5px] items-start sm:!items-center justify-between transition-all mb-[10px] sm:!mb-0">
            Abdulrahman Elheyb
            <u className="bg-green bg-opacity-60">React & Node Developer</u>
          </div>
        </li>
        <li className="cursor-default hover:opacity-[1] opacity-[0.5]">
          <div className="flex flex-col sm:!flex-row gap-[5px] items-start sm:!items-center justify-between transition-all mb-[10px] sm:!mb-0">
            Aziz Karaburun
            <u className="bg-green bg-opacity-60">
              AI & Image Process Developer
            </u>
          </div>
        </li>
        <li className="cursor-default hover:opacity-[1] opacity-[0.5]">
          <div className="flex flex-col sm:!flex-row gap-[5px] items-start sm:!items-center justify-between transition-all mb-[10px] sm:!mb-0">
            Musa Çelik
            <u className="bg-green bg-opacity-60">SEO & Content Expert</u>
          </div>
        </li>
        <li className="cursor-default hover:opacity-[1] opacity-[0.5]">
          <div className="flex flex-col sm:!flex-row gap-[5px] items-start sm:!items-center justify-between transition-all mb-[10px] sm:!mb-0">
            Koray Buğer
            <u className="bg-green bg-opacity-60">Web3 Project Manager</u>
          </div>
        </li>
        <li className="cursor-default hover:opacity-[1] opacity-[0.5]">
          <div className="flex flex-col sm:!flex-row gap-[5px] items-start sm:!items-center justify-between transition-all mb-[10px] sm:!mb-0">
            Hakan Aslantürk
            <u className="bg-green bg-opacity-60">Branding Manager</u>
          </div>
        </li>
      </ul>
    </div>
  );
};
